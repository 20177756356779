<template>
  <div class="page">
    <BreadCrumb />
    <div class="page-body">
       <div class="box">
          <div>
            <div>说明：</div>
            <div>1、学习函数导入位于./Data目录下的*.csv文件作为数据表集X，与./training_tags.txt文件中的列标签集Y，并建立更新字典或学习模型并存于./Model目录下。</div>
            <div>2、服务类型：指需要执行的。共“build_corpus_front_end、updata_corpus_front_end、build_model_front_end、updata_corpus_back_end、updata_model_back_end、build_model_back_end”六种类型，功能分别为建立语料库，更新语料库，建立模型。</div>
            <div>3、标签库：输入需要识别的标签类型，以逗号分隔，单个标签前后不要有空格除非标带有空格。格式为["tab1","tab2","tab3","tab4","tab5","tab6","tab7","tab8"]</div>
            <div>4、本次学习标签：即本次要学习的标签，格式为[["tab1","tab3","tab5","tab7","tab9"]]的二维数组，第一层代表数据集，第二层代表列，与tb_list顺序保持一致。</div>
            <div>5、学习数据量：指本次学习的数据量。</div>
            <div>6、Y_position：指列标签集Y是否以标签在标签库tablib中的位置来表示标签。“是”为使用，“否”为使用标签本身的名字。</div>
          </div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm m-t-20">
            <el-form-item label="标签库" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="服务类型" prop="region">
              <el-select v-model="ruleForm.region" placeholder="请选择执行类型">
                <el-option label="build_corpus_front_end" value="build_corpus_front_end"></el-option>
                <el-option label="build_model_front_end" value="build_model_front_end"></el-option>
                 <el-option label="updata_corpus_back_end" value="updata_corpus_back_end"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="数据集" prop="model">
              <el-input v-model="ruleForm.model"></el-input>
            </el-form-item>
            
            <el-form-item label="学习标签" prop="id">
              <el-input v-model="ruleForm.id"></el-input>
            </el-form-item>
            <el-form-item label="学习数据量" prop="num" class='num'>
              <el-input v-model="ruleForm.num1"></el-input> <span class='span'> --</span> <el-input v-model="ruleForm.num2"></el-input>
            </el-form-item>
            <el-form-item label="特殊资源">
                <el-radio-group v-model="ruleForm.resource">
                  <el-radio label="是"></el-radio>
                  <el-radio label="否"></el-radio>
                </el-radio-group>
              </el-form-item>
            <el-form-item label="分类器(clf)" prop="classification">
              <el-select v-model="ruleForm.classification" placeholder="请选择分类器">
                <el-option label="决策树算法" value="决策树算法"></el-option>
                <el-option label="支持向量机" value="支持向量机"></el-option>
                 <el-option label="逻辑回归" value="逻辑回归"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
              <el-button @click="resetForm()">取消</el-button>
            </el-form-item>
          </el-form>
       </div>
    </div>
  </div>

</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb' //面包屑

export default {
  name: 'modelTraining',
  //注册组件
  components: {
    BreadCrumb,
  },
  data() {
      return {
        ruleForm: {
          name: '',
          model:'',
          region: '',
          classification:'',
          resource: "是",
          id:'',
          num1:1,
           num2:1
        },
        rules: {
          name: [
            { required: true, message: '请输入标签库', trigger: 'blur' },
            { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
          model: [
            { required: true, message: '请输入数据集', trigger: 'blur' },
            { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
          region: [
            { required: true, message: '请选择服务类型', trigger: 'change' }
          ],
          id: [
            { required: true, message: '请输入学习标签', trigger: 'blur' },
            { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
          classification: [
            { required: true, message: '请选择分类器', trigger: 'blur' },
          ],
        }
      };
    },
  //挂在完成 此时一般可以做一些ajax操作，mounted只会执行一次。
  mounted: function () {
  },
  methods: {
     submitForm(formName) {
            this.$refs[formName].validate((valid) => {
              if (valid) {
                this.$router.push({
                  name: 'qualityModel'
                })
              } else {
                console.log('error submit!!');
                return false;
              }
            });
          },
    resetForm() {
      this.$router.push({
        name: 'qualityModel'
      })
    },
  },
}
</script>

<style scoped>
  .box{
    padding: 0 5vw;
  }
  .box>div{
    padding: 5px 10px;
    background-color: rgba(242, 242, 242, 1);
  }
  .box .num:deep(.el-form-item__content) {
    display: flex;
  }
  .box .num:deep(.el-form-item__content .el-input) {
    width: 100px;
  }
  .box .num .span {
        width: 60px;
        text-align: center;
  }
</style>
